//import Modal from './modules/modal.js'; //quite
import objectFitImages from "./modules/objectfit.js"; //quite
import Swiper from "./extern/swiper-bundle-min.js"; //quite
//import mobileMenu from './modules/mobile-menu.js'; //quite

//Touch sniffer
if ("ontouchstart" in document.documentElement) {
  document.querySelector("html").classList.add("is-touch");
}

// Init functions on pageload
function init() {
  objectFitImages();
  // mobileMenu();
  // new Modal();

  // dialog popup
  const dialogTriggers = document.querySelectorAll("[data-dialog-trig]");

  dialogTriggers.forEach((element) => {
    element.addEventListener("click", function () {
      const dialogName = this.dataset.dialogTrig;
      const dialogElement = document.querySelector(`[data-dialog="${dialogName}"]`);
      dialogElement.classList.add("show");
    });
  });

  const closeButtons = document.querySelectorAll("dialog .sluiten");

  closeButtons.forEach((element) => {
    element.addEventListener("click", function () {
      const firstDialogParent = this.closest("dialog");
      firstDialogParent.classList.remove("show");
    });
  });

  window.addEventListener("click", function (event) {
    if (!event.target.closest("dialog") && !event.target.closest("[data-dialog-trig]")) {
      const dialogElements = document.querySelectorAll("dialog.show");
      dialogElements.forEach((element) => element.classList.remove("show"));
    }
  });

  // swipers
  var swiper = new Swiper(".swiper-2023", {
    autoplay: { delay: 5000 },
    speed: 400,
    grabCursor: true,
    loop: false,
    spaceBetween: 20,
    breakpoints: {
      // breakpoints gelden voor alles erboven
      0: {
        slidesPerView: 1,
      },
      767: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
    navigation: {
      nextEl: ".arrow-2023-next",
      prevEl: ".arrow-2023-prev",
    },
    on: {
      afterInit: function () {
        // Get the current slide element
        const nextSlide = this.slides[this.activeIndex + this.params.slidesPerView];

        // Style the next slide element
        nextSlide.classList.add("slide-spacing");
      },
      slideChange: function () {
        // Get the current slide element
        const nextSlide = this.slides[this.activeIndex + this.params.slidesPerView];

        this.slides.forEach((slide) => {
          slide.classList.remove("slide-spacing");
        });

        // Style the next slide element
        nextSlide.classList.add("slide-spacing");
      },
    },
  });
}

// removes weird U+2028 character from figma copy
document.body.innerHTML = document.body.innerHTML.replace("\u2028", "<br />");

jQuery(document).ready(function ($) {
  init();
});
